import React from "react";
import "./Cart.css";
import Button from "../Button/Button";

function Cart({ cartItems, onCheckout }) {
  const totalPrice = Array.isArray(cartItems)
    ? cartItems.reduce((a, c) => a + c.price * c.quantity, 0)
    : 0;

  return (
    <div className="cart__container">
      <div>
        {cartItems.length === 0 ? "No items in cart" : ""}
        <br />
        <span className=""> Total Price: Ksh. {totalPrice.toFixed(2)}</span>
      </div>
      <Button
        title={cartItems.length === 0 ? "Order" : "Checkout"}
        type={"checkout"}
        disable={cartItems.length === 0 ? true : false}
        onClick={onCheckout}
      />
    </div>
  );
}

export default Cart;
