import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content">
        <p>&copy; 2024 Website by Richee.</p>
        <ul className="footer__links">
          <li>
            <a href="#about">About Us</a>
          </li>
          <li>
            <a href="mailto: richeenjugush@gmail.com">Contact</a>
          </li>
          <li>
            <a href="#privacy">Privacy Policy</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
