import pizzaImg from "../images/pizza.png";
import burgerImg from "../images/burger.png";
import cocaImg from "../images/coca.png";
import saladImg from "../images/salad.png";
import waterImg from "../images/water.png";
import IcecreamImg from "../images/icecream.png";
import kebabImg from "../images/kebab.png";

export function getData() {
  return [
    { id: 1, title: "Large Pizza", price: 2500, Image: pizzaImg },
    { id: 2, title: "Burger", price: 550, Image: burgerImg },
    { id: 3, title: "Soda", price: 40, Image: cocaImg },
    { id: 4, title: "Salad", price: 120, Image: saladImg },
    { id: 5, title: "Water", price: 50, Image: waterImg },
    { id: 6, title: "Ice-cream", price: 20, Image: IcecreamImg },
    { id: 7, title: "Kebab", price: 50, Image: kebabImg },
  ];
}

// module.exports = { getData };
